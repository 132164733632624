import React, { useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import options from "../../templates/richTextRenderers";
import { makeStyles } from "@material-ui/core/styles";
import useForm, { DEFAULT_FIELD } from "../../hooks/useForm";
import Email, {
  validator as emailValidator,
} from "../../components/Form/inputs/Email";
import FullName, {
  validator as nameValidator,
} from "../../components/Form/inputs/FullName";
import ButtonWithLoading from "../../components/Form/inputs/ButtonWithLoading";
import { withFirebase } from "../../components/Firebase";
import addToMailchimp from "gatsby-plugin-mailchimp";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexGrow: 1,
  },
  formButtons: {
    width: "100%",
  },
  line: {
    borderTop: `2px solid ${theme.palette.secondary.main}`,
    width: "100px",
  },
  title: {
    fontWeight: "bold",
  },
  buttonContained: {
    padding: "10px 58px",
    fontSize: "1.1875rem",
    fontWeight: 600,
    borderRadius: "0px",
    marginLeft: "-8px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },
  fields: {
    backgroundColor: "#e67c7b",
    color: theme.palette.secondary.black,
    "& .MuiInputBase-input": {
      padding: "14px",
    },
  },
}));

const getBackgroundColor = (backgroundColor) =>
  backgroundColor === "White" ? "background.default" : "secondary.main";

const validators = {
  email: emailValidator,
  fullName: nameValidator,
};

const submitSignUpWithEmail = async ({
  event,
  inputs,
  props,
  setInputs,
  setSuccess,
}) => {
  event.preventDefault();
  const { fullName, email } = inputs.fields;

  try {
    const result = await addToMailchimp(email.value, { FNAME: fullName.value });

    if (result.result === "success") {
      setInputs((nextInputs) => ({
        ...nextInputs,
        fields: {
          ...nextInputs.fields,
          fullName: DEFAULT_FIELD,
          email: DEFAULT_FIELD,
        },
      }));
      setSuccess(true);
    } else {
      console.error("Mailchimp returned an error:", result.msg);
      setSuccess(false);
    }
  } catch (error) {
    console.error("Error submitting to Mailchimp:", error);
    setSuccess(false);
  }

  props.setAnchor();
  return {
    result: {},
  };
};

const ApplyCta = (props) => {
  const data = props.data[props.componentId];

  const classes = useStyles();
  const bgcolor = getBackgroundColor(data.backgroundColor);

  const boxRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const setAnchor = () => setAnchorEl(boxRef.current);

  const {
    inputs,
    handleInputChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    loading,
    success,
  } = useForm(
    { ...props, setAnchor },
    submitSignUpWithEmail,
    { fullName: DEFAULT_FIELD, email: DEFAULT_FIELD },
    validators
  );

  const open = Boolean(anchorEl && success);
  const id = success ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box py={5} bgcolor={bgcolor}>
      <Container maxWidth="lg">
        <Grid container spacing={5} alignItems="flex-end">
          <Grid item xs={12} md={5}>
            <Typography variant="h1" gutterBottom className={classes.title}>
              <Box pt={5} width="70%">
                {data.title}
              </Box>
            </Typography>
            <hr align="left" className={classes.line} />
            <Typography component="span">
              {documentToReactComponents(data.text.json, options())}
            </Typography>
          </Grid>
          <Grid item md={1}></Grid>
          <Grid item xs={12} md={6}>
            <div>
              <form
                onSubmit={(event) => handleSubmit(event, inputs)}
                className={classes.container}
              >
                <Box
                  pb={3}
                  ref={boxRef}
                  className={classes.formButtons}
                  alignItems="column"
                >
                  <Box py={1}>
                    <FullName
                      fullWidth
                      handleInputChange={handleInputChange}
                      handleBlur={handleBlur}
                      handleFocus={handleFocus}
                      inputs={inputs}
                      className={classes.fields}
                      placeholder={data.namePlaceholder}
                    />
                  </Box>
                  <Box pt={1} pb={2}>
                    <Email
                      fullWidth
                      handleInputChange={handleInputChange}
                      handleBlur={handleBlur}
                      handleFocus={handleFocus}
                      inputs={inputs}
                      className={classes.fields}
                      placeholder={data.emailPlaceholder}
                    />
                  </Box>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <Paper>
                      <Box p={10}>
                        <Typography variant="h5">
                          We received your request. Thank you!
                        </Typography>
                      </Box>
                    </Paper>
                  </Popover>
                  <Box>
                    <ButtonWithLoading
                      className={classes.buttonContained}
                      disableElevation
                      variant="contained"
                      size="large"
                      color="primary"
                      loading={loading}
                      success={success}
                      type="submit"
                      label={data.ctaLabel}
                    />
                  </Box>
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withFirebase(ApplyCta);
