import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../components/Button';
import componentFinder from '../util/componentFinder';
import options from '../../templates/richTextRenderers';

const useStyles = props =>
  makeStyles(theme => ({
  mainTitle:{
    fontSize: '2.00192rem',
    lineHeight: '1.3',
    fontWeight: 'bold',
    color: theme.palette.primary.black,
    // paddingTop: '10%',
    [theme.breakpoints.down('md')]: {
    paddingTop: '0px',
      },
  },
  highlighImageContainer:{
    maxHeight: '180px',
  },
  highlight: {
    position: 'relative',
    top: -122,
    [theme.breakpoints.down('sm')]: {
      top: -8,
    },
  },
  highlightTitle:{
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  highlightText:{
    fontSize: '1rem',
    fontFamily:'IBM Plex Mono',
    color: theme.palette.background.default,
    '&:li': {
        color: theme.palette.secondary.main,
      },
  },
  highlightImage:{
    position: 'relative',
    top: -172,
    [theme.breakpoints.down('sm')]: {
        top: -8,
      },
  },
  mainText:{
    fontSize: '1.2512rem',
    color: theme.palette.text.primary,
  },
  secondaryText:{
    fontFamily:'IBM Plex Mono',
    fontSize: '1rem',
    color: theme.palette.primary.black,
  },
    buttonContained: {
      fontSize: '1.2512rem',
      fontWeight: 600,
      borderRadius:'0px',
      border: 'solid 3px #B90605',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
    },
    border: {
      borderTop:'double 6px #B90605',
      borderBottom:'double 6px #B90605',
    },
    noBorder: {
      borderTop: 'none',
      borderBottom:'none',
    },
    buttonOutlined: {
      fontSize: '1.2512rem',
      fontWeight: 600,
      borderRadius:'0px',
      border: 'solid 4px #B90605',
      '&:hover': {
        borderColor: theme.palette.primary.light,
      },
    },
}));

let getBackgroundColor = backgroundColor =>
  backgroundColor === 'White' ? 'background.default' : 'secondary.main';

const getDirection = orientation =>
  orientation === 'Image Left' ? 'row' : 'row-reverse';



const link = name => (name === 'index' ? '/' : `/${name}`);

export default props => {
  const data = props.data[props.componentId]

  const classes = useStyles({ ...props, data })();

  const getImageBorder = imageBorder =>
  imageBorder === 'Yes'? classes.border : classes.noBorder;

  const bgcolor = getBackgroundColor(data.backgroundColor);
  const direction = getDirection(data.orientation);

  const haveHighlightTitle = Boolean(data.highlightTitle);


  const haveHighlightTextOrImage = (input, classes) => {
    if (input === 'Text'){
        return (
      <Grid item container direction={direction} justify="flex-end">
          <Grid item md={12} lg={10}>  
          <Box className={classes.highlight} bgcolor="secondary.black" p={3}> 
              <Box display="flex" flexDirection="column">
              {haveHighlightTitle &&
              <Typography className={classes.highlightTitle} color='primary'>
                  {data.highlightTitle}
              </Typography>
              }
              <Typography component="span" className={classes.highlightText}>
                  {documentToReactComponents(data.highlightText.json, options())}
              </Typography>
              </Box>
          </Box>
      </Grid>
  </Grid>
        )
    } else if ( input ==='Image'){
      return (
        <Grid item container direction={direction} justify="flex-end" className = {classes.highlighImageContainer}>
        <Grid item container md={12} lg={10} direction={direction} justify='flex-end'>  
            <Box display={{ xs: 'none', lg: 'block' }} className={classes.highlightImage} width='50%'>
                <Box display="flex" flexDirection="column">
                <Img fluid={data.highlightImage.fluid} alt={data.description}/>
                </Box>
            </Box>
        </Grid>
    </Grid> 
      )
    }
    return (
      ''
        )
  };

  const linkToPageOrForm = input => {
    if(input === 'Form') {
      return (
        <Button className = {classes.buttonContained} target='blank' variant="contained" disableElevation size="large" color='primary'  onClick={() => props.navigate(link(data.leftButtonLinkToForm))}>
          {data.leftButtonLabel}
      </Button>
      )
    }
   else if( input === 'Page'){
    if(data.leftButtonLink.name){
      return(
      <Button className = {classes.buttonContained} variant="contained" disableElevation size="large" color='primary' to={`/${data.leftButtonLink.name}`} >
        {data.leftButtonLabel}
      </Button>
      )
    }
    return(
      <Button className = {classes.buttonContained} target="blank" variant="contained" disableElevation size="large" color='primary' to={data.leftButtonLink.pdfLink} >
        {data.leftButtonLabel} 
      </Button>
    )
  }
};

const haveRight = input => {
  if(input){
    if(data.rightButtonLink.name) {
      return (
        <Button className = {classes.buttonOutlined} variant="outlined" size="large" color='default' to={`/${data.rightButtonLink.name}`} >
        {data.rightButtonLabel}
        </Button>
      )
    }
    return (
      <Button className = {classes.buttonOutlined} target='blank' variant="outlined" size="large" color='default' to={data.rightButtonLink.pdfLink} >
      {data.rightButtonLabel}
      </Button>
    )
  }

}


  const textOrImage = haveHighlightTextOrImage(data.highlightTextOrImage, classes);

  const haveSecondaryText = Boolean(data.secondaryText);


  const haveRightButton = haveRight(data.rightButtonLabel);
  const anchorLink = Boolean(data.anchor);
  const formOrPage = linkToPageOrForm(data.linkToFormOrPage);
  
  const checkIcon = (input) =>{
    if (input){
      return (
        <Grid item xs={12}>
          <Box width='20%' pt={3}>
            <Img fluid={data.icon.fluid}/>
          </Box>
        </Grid>
      )
    }
      return(
        ''
      )
  }
  
  const haveIcon = checkIcon(data.icon);

  return (
    <Box py={6} bgcolor={bgcolor}>
    {anchorLink && <Box id={data.anchor}></Box>}
      <Container maxWidth="lg">
        <Grid container spacing={4} direction={direction} alignItems="flex-start">
          <Grid item container sm={12} md={6}>
            <Grid item container direction={direction}>
              <Grid item xs={12} lg={10}>
                <Img className={getImageBorder(data.imageBorder)} fluid={data.mainImage.fluid} alt={data.description}/>
              </Grid>
              {textOrImage}
            </Grid>
          </Grid>
          <Grid item container sm={12} md={6}> 
              {haveIcon}
            <Grid item xs={12}>
              <Typography className={classes.mainTitle}>
                  {data.title}
              </Typography>
            </Grid>

            <Grid item container alignItems="flex-start">

                <Typography component="span" className={classes.mainText} gutterBottom>               
                    {documentToReactComponents(data.mainText.json, options())}
                </Typography>

              <Box width='100%' bgcolor={bgcolor} px={2}>
                <Typography component="span" className={classes.secondaryText}>  
                  {haveSecondaryText && documentToReactComponents(data.secondaryText.json, options())}
                </Typography>
              </Box>
            </Grid>

           
            <Grid item container direction="column" alignItems="flex-start" justify="center">
                <Box display='flex' direction='row'>
                    <Box mt={2}>
                      {formOrPage}
                    </Box>
                    <Box mx={2}></Box>
                    <Box mt={2}>
                      {haveRightButton}
                    </Box>                    
                </Box>
            </Grid>

          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
